import React from 'react'
import PropTypes from "prop-types";
import PropRadio from "../../common/fields/PropRadio";
import PropType from "../../../shared/constants/PropType";
import {PropHint} from "../../common/widgets/PropHint";

const getOptions = (type, game) => {
    if (type === PropType.OVER_UNDER) {
        return ["Under", "Over"];
    } else if (type === PropType.YES_NO) {
        return ["Yes", "No"];
    } else if (type === PropType.ODD_EVEN) {
        return ["Odd", "Even"];
    } else if (type === PropType.TEAM) {
        return [game.awayTeam, game.homeTeam];
    } else if (type === PropType.COLOUR) {
        return ["Clear", "Yellow", "Orange", "Red", "Green", "Blue", "Purple", "Other"];
    } else {
        return ["Invalid Type"];
    }
}

const PropRow = ({propData, game, currentValue, onPropUpdated}) => {

    const isText = propData.type === PropType.TEXT;
    const isMultiSelect = propData.type === PropType.COLOUR;
    const isToggle = !isText && !isMultiSelect;

    const options = getOptions(propData.type, game);

    const onUpdate = (value) => {
        onPropUpdated(propData, value);
    }

    const showLiveValue = game.status === 'LIVE' && propData.liveValue !== undefined;

    return (
        <div className="prop-row">
            <div className="prop-desc">
                <div>
                    {propData.description}
                    {propData.hint &&
                        <PropHint prop={propData}></PropHint>
                    }
                </div>
            </div>
            {showLiveValue && <div className="prop-live-value">Live: {propData.liveValue}</div>}
            {isText &&
                <input className="form-control prop-select" type="text"
                       value={currentValue}
                       onChange={(e) => onUpdate(e.target.value)}></input>
            }
            {isMultiSelect &&
                <select className="form-control prop-select"
                        value={currentValue}
                        onChange={onUpdate}>
                    {options.map((option, index) => {
                        return <option key={index}>{option}</option>;
                    })}
                </select>
            }
            {isToggle &&
                <PropRadio updateValue={onUpdate}
                           options={options}
                           currentValue={currentValue}
                           propLine={propData.value}
                ></PropRadio>
            }
        </div>
    )
}

PropRow.propTypes = {
    propData: PropTypes.object.isRequired,
    game: PropTypes.object.isRequired,
    currentValue: PropTypes.any,
    onPropUpdated: PropTypes.func.isRequired
};

export default PropRow;
