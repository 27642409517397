import React, {useState} from 'react'
import {put} from '../../../shared/services/ApiService';
import {buildModal, useModal} from "../../common/modals/Modal";

export const PaymentManagerRow = ({entry}) => {
    const [paid, setPaid] = useState(entry.paid);
    const [prompted, setPrompted] = useState(false);
    const [loading, setLoading] = useState(false);

    const {setModal} = useModal();

    const recordPayment = (currentEntry) => {
        setLoading(true);
        put(`api/games/entry/${currentEntry.id}/payment?paid=${!paid}`).then(isPaid => {
                setPaid(isPaid);
                setLoading(false);
            },
            error => {
                const modal = buildModal("Error", "Couldn't update payment.");
                setModal(modal);
                setLoading(false);
                console.error(error);
            });
    }

    const promptForPayment = (currentEntry) => {
        setLoading(true);
        put(`api/games/entry/${currentEntry.id}/payment/prompt`).then(() => {
                setLoading(false);
                setPrompted(true);
            },
            error => {
                const modal = buildModal("Error", "Couldn't send payment reminder.");
                setModal(modal);
                setLoading(false);
                console.error(error);
            });
    }

    const paymentIcon = paid ? "fas fa-times-circle" : "fas fa-check-circle";

    return (
        <div className="payment-manager-row">
            {entry.firstName}&nbsp;{entry.lastName}&nbsp;
            {!loading &&
                <a onClick={() => recordPayment(entry)}>&nbsp;<i className={paymentIcon}></i></a>
            }
            {!paid && !loading && !prompted &&
                <a onClick={() => promptForPayment(entry)}>&nbsp;<i className="fas fa-bell"></i></a>
            }
        </div>
    )
}