import React from 'react'
import PropTypes from "prop-types";

const LabeledCheckbox = ({name, label, value, onChange}) => {

    return (
        <div className="pb-field-group pb-checkbox">
            <label>{label}</label>
            <input className="pb-field"
                   key={Math.random()}
                   type="checkbox"
                   onChange={() => onChange(name, !value)}
                   value={value}
                   defaultChecked={value}
            ></input>
        </div>
    );
}

LabeledCheckbox.propTypes = {
    name: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired
};

export default LabeledCheckbox;