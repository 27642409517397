import React from 'react'
import PropTypes from "prop-types";
import PropCategory from '../../../shared/constants/PropCategory';
import EditPropModal from "./EditPropModal";
import {buildModal, useModal} from "../../common/modals/Modal";
import * as PropBuilderService from "../../../shared/services/PropBuilderService";

const PropRow = ({index, prop, orderBefore, orderAfter, deleteProp, updateProp}) => {

    const {setModal} = useModal();

    const deletePropRow = () => {
        deleteProp(index);
    }

    const editProp = () => {
        const modal = <EditPropModal index={index} prop={prop} onPropSaved={updateProp}></EditPropModal>;
        setModal(modal);
    }

    const updateOrder = (direction) => {
        let newOrder;
        if (direction === 1) {
            newOrder = orderAfter + 1;
        } else {
            newOrder = orderBefore - 1;
        }
        const request = {
            order: newOrder
        }
        PropBuilderService.editProp(prop.id, request).then(result => {
                updateProp(index, result);
            },
            error => {
                const modal = buildModal("Error", "Couldn't update prop order.");
                setModal(modal);
                console.error(error);
            }
        );
    }

    const getIconClass = () => {
        if (prop.category === PropCategory.GAME) {
            return "fas fa-trophy";
        } else if (prop.category === PropCategory.PLAYER) {
            return "fas fa-child";
        } else {
            return "fas fa-pen";
        }
    }

    return (
        <div className="prop-item-container">
            <i className={`category-icon ${getIconClass()}`}></i>
            <div className="prop-item-description">
                {`${prop.description} - ${prop.type} ${!!prop.value ? prop.value : ""}`}
            </div>
            <div className="action-btns">
                {orderBefore && <a className="fas fa-arrow-up" onClick={() => updateOrder(-1)}></a>}
                {orderAfter && <a className="fas fa-arrow-down" onClick={() => updateOrder(1)}></a>}
                <a className="fas fa-pencil-alt" onClick={editProp}></a>
                <a className="fas fa-trash" onClick={deletePropRow}></a>
            </div>
        </div>
    )
}

PropRow.propTypes = {
    index: PropTypes.number.isRequired,
    prop: PropTypes.object.isRequired,
    deleteProp: PropTypes.func.isRequired
};

export default PropRow;