import React, {useState} from 'react'
import {put} from "../../../../shared/services/ApiService";
import PropTypes from "prop-types";
import ErrorPanel from "../../../common/error/ErrorPanel";
import LabeledTextArea from "../../../common/fields/LabeledTextArea";
import PbButton from "../../../common/buttons/PbButton";

const InviteMessageEditor = ({ticket}) => {
    const [isSaving, setSaving] = useState(false);
    const [errors, setErrors] = useState(null);

    const [inviteMessage, setInviteMessage] = useState(ticket.description || "");

    const updateMessage = () => {
        const request = {
            description: inviteMessage
        };
        setSaving(true);
        put(`api/tickets/${ticket.id}`, request).then(() => {
                setSaving(false);
            },
            error => {
                setErrors(error);
                console.error(error);
            });
    }

    return (
        <div className="invite-container">
            <ErrorPanel errors={errors}></ErrorPanel>
            <LabeledTextArea onChange={(value) => setInviteMessage(value)}
                             value={inviteMessage}
                             label=""></LabeledTextArea>
            <div className="props-modal-btns">
                <PbButton onClick={() => updateMessage()} label="Save Message" isLoading={isSaving}></PbButton>
            </div>
        </div>
    )
}

InviteMessageEditor.propTypes = {
    ticket: PropTypes.object.isRequired
}

export default InviteMessageEditor;