import React, {useState} from 'react'
import PropTypes from "prop-types";
import PbButton from "../../common/buttons/PbButton";
import {useModal, closeModal, buildModal} from "../../common/modals/Modal";
import * as PropBuilderService from "../../../shared/services/PropBuilderService";

const EditPropModal = ({index, prop, onPropSaved}) => {
    const {setModal} = useModal();

    const [isSaving, setSaving] = useState(false);
    const [hint, setHint] = useState(prop.hint);
    const [value, setValue] = useState(prop.value);
    const [description, setDescription] = useState(prop.description);

    const onHintUpdated = (hintValue) => {
        setHint(hintValue);
    }

    const onValueUpdated = (propValue) => {
        setValue(propValue);
    }

    const onDescriptionUpdated = (propValue) => {
        setDescription(propValue);
    }

    const cancel = () => {
        closeModal(setModal);
    }

    const saveProp = () => {
        setSaving(true);
        const request = {
            value: value,
            hint: hint,
            description: description
        }
        PropBuilderService.editProp(prop.id, request).then(result => {
                onPropSaved(index, result);
                closeModal(setModal);
            },
            error => {
                const modal = buildModal("Error", "Couldn't edit prop.");
                setModal(modal);
                console.error(error);
            }
        );
    }

    return (
        <div className="props-modal-body prop-builder">
            <div className="props-modal-title">
                Prop Editor
            </div>
            {prop.category === 'CUSTOM' &&
                <div className="field-row">
                    <label>O/U Value</label>
                    <input className="row-right" type="text" value={description || ""}
                           onChange={(e) => onDescriptionUpdated(e.target.value)}></input>
                </div>
            }
            {prop.type === 'OVER_UNDER' &&
                <div className="field-row">
                    <label>O/U Value</label>
                    <input className="row-right" type="number" value={value || ""}
                    onChange={(e) => onValueUpdated(e.target.value)}></input>
                </div>
            }
            <div className="field-row">
                <label>Hint</label>
                <textarea className="row-right" value={hint || ""}
                       onChange={(e) => onHintUpdated(e.target.value)}></textarea>
            </div>

            <div className="props-modal-btns">
                <PbButton onClick={cancel} label="Cancel" isLoading={isSaving} btnClass="cancel"></PbButton>
                <PbButton onClick={() => saveProp()} isLoading={isSaving}
                          label="Save"></PbButton>
            </div>
        </div>
    )
}

EditPropModal.propTypes = {
    prop: PropTypes.object.isRequired,
    onPropSaved: PropTypes.func.isRequired
}

export default EditPropModal;