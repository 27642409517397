import React, {useState} from 'react';
import Sport from '../../../shared/constants/Sport';
import BaseModal from "../../common/modals/BaseModal";
import {closeModal, useModal} from "../../common/modals/Modal";
import GameSchedule from "./GameSchedule";

const sportMap = [
    {
        label: "Custom",
        type: Sport.CUSTOM
    },
    {
        label: "Hockey",
        type: Sport.HOCKEY
    },
    {
        label: "Football",
        type: Sport.FOOTBALL
    },
    {
        label: "Baseball",
        type: Sport.BASEBALL
    },
    {
        label: "Basketball",
        type: Sport.BASKETBALL
    }
];

const GameSelectorModal = ({onGameSelected}) => {
    const [sport, setSport] = useState(Sport.CUSTOM);
    const {setModal} = useModal();

    const onSelect = (game) => {
        onGameSelected(game);
        closeModal(setModal);
    }

    return (
        <BaseModal title="Select Sport" style="game-selector">
            <select className="pb-field" defaultValue={sport} onChange={(e) => setSport(e.target.value)}>
                <option value="" disabled hidden></option>
                {sportMap.map((option, index) => {
                    return <option key={index} value={option.type}>{option.label}</option>;
                })}
            </select>
            <GameSchedule sport={sport} onSelect={onSelect}></GameSchedule>
        </BaseModal>
    )
}

export default GameSelectorModal;