import {buildModal, useModal} from "../components/common/modals/Modal";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {hasPermission, isAuthenticated} from "../shared/services/AuthService";
import {Login} from "../components/account/Login";
import {HOME} from "../shared/constants/RouteContants";


const ProtectedRoute = ({permission, permissionError, children}) => {
    const {setModal} = useModal();
    const navigate = useNavigate();

    let errorMessage;
    if (permissionError) {
        errorMessage = permissionError;
    } else {
        errorMessage = "You don't have permission to view this page.";
    }

    useEffect(() => {
        if (!isAuthenticated()) {
            setModal(
                <div style={{position: 'relative'}}>
                    <Login></Login>
                </div>
            );
        } else if (permission && !hasPermission(permission)) {
            setModal(buildModal("Not Permitted", errorMessage,
                () => navigate(HOME)
            ));
        }
    }, []);

    useEffect(() => {
        if (isAuthenticated() && permission && !hasPermission(permission)) {
            setModal(buildModal("Not Permitted", errorMessage,
                () => navigate(HOME)
            ));
        }
    }, [permission]);

    if (!isAuthenticated()) {
        return;
    }

    if (permission && !hasPermission(permission)) {
        return;
    }

    return children;
}

export default ProtectedRoute;