import React from 'react'
import PropTypes from 'prop-types';
import DateService from '../../../shared/services/DateService';
import PbButton from "../../common/buttons/PbButton";

const GameScheduleRow = ({game, onSelect}) => {
    const startTime = DateService.parseDate(game.gameDate, "h:mm A");
    const awayName = game.away.fullName;
    const homeName = game.home.fullName;

    const onClick = () => {
        onSelect(game);
    };

    return (
        <div className="schedule-row">
            <div className="date-label">{startTime}</div>
            <div className="team-label">{awayName}</div>
            <div className="vs-label">vs</div>
            <div className="team-label">{homeName}</div>
            <PbButton onClick={onClick} label="Add"></PbButton>
        </div>
    );
}

GameScheduleRow.propTypes = {
    game: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired
}

export default GameScheduleRow;