import React, {useEffect, useState} from 'react'
import {get, post} from '../../../shared/services/ApiService';
import {PageLoader} from "../../common/widgets/PageLoader";
import {useParams} from "react-router-dom";
import {buildModal, useModal} from "../../common/modals/Modal";
import {PropsEditor} from "./PropsEditor";
import PbButton from "../../common/buttons/PbButton";
import PropTypes from "prop-types";
import PbTabContainer, {SECOND_LEVEL_NAV} from "../../common/containers/PbTabContainer";

const PropEntry = ({ticket}) => {
    const [entryJson, setEntryJson] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isDirtyModel, setDirtyModel] = useState(false);
    const params = useParams();

    const totalPropCount = ticket.games.reduce((count, game) => count + game.props.length, 0);

    const {setModal} = useModal();

    const loadData = () => {
        const ticketId = params.ticketId;
        get(`api/tickets/${ticketId}/entry`).then(response => {
            setEntryJson(response.legacyViewModelJson || {})
            setLoading(false);
        })
    }

    const onNavigate = (e) => {
        if (!isDirtyModel) {
            return undefined;
        }

        const confirmationMessage = 'Are you sure you want to leave without saving?';
        e.returnValue = confirmationMessage;
        return confirmationMessage;
    };

    useEffect(() => {
        window.addEventListener('beforeunload', onNavigate)
        return () => window.removeEventListener('beforeunload', onNavigate)
    }, [isDirtyModel]);

    const submitProps = () => {
        const saveEntryRequest = {
            entryJson: entryJson
        }

        post(`api/tickets/${ticket.id}/entry`, saveEntryRequest).then(() => {
                setDirtyModel(false);
                const modal = buildModal("Picks Submitted",
                    "Thanks for playing! Your picks have been submitted. You can continue to change your picks until the game has been locked.",
                    () => window.location.reload(), "OK");
                setModal(modal);
            },
            error => {
                console.error(error);
                const modal = buildModal("Submission Error", error);
                setModal(modal);
            })
    }

    const saveProps = () => {
        if (Object.keys(entryJson).length < totalPropCount) {
            const modal = buildModal("Incomplete Picks", "You have not answered all props.",
                () => submitProps(), "Save Anyways", "", "Cancel");
            setModal(modal);
        } else {
            submitProps();
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    if (loading) {
        return (
            <div className="props-page-body-dark">
                <PageLoader></PageLoader>
            </div>
        );
    }

    const onPropUpdated = (updatedProp, value) => {
        setDirtyModel(true);
        setEntryJson((prevState) => {
            return ({
                ...prevState,
                [updatedProp.key]: value
            })
        });
    }

    const gameTabs = ticket.games.map((game, index) => {
        return ({tabName: game.name, tabContent:
                <PropsEditor key={index} game={game} onPropUpdated={onPropUpdated} viewModel={entryJson}></PropsEditor>})
    });

    return (
        <div>
            <PbTabContainer tabs={gameTabs} navLevel={SECOND_LEVEL_NAV} showNavButtons={true}></PbTabContainer>
            <div className="pb-btn-row">
                <PbButton label="Save Props" onClick={saveProps}></PbButton>
            </div>
        </div>
    )
}

PropEntry.propTypes = {
    ticket: PropTypes.object.isRequired
}

export default PropEntry;

