import React, {useState} from 'react';
import PropTypes from 'prop-types';
import PbButton from "../buttons/PbButton";

const SubmitTextField = ({label, placeholder, disabled, onSubmit}) => {
    const [value, setValue] = useState("");

    const onSubmitValue = () => {
        onSubmit(value);
        setValue("");
    }
    return (
        <div className="submit-text-field">
            <input className="pb-field"
                   type="text" value={value}
                   placeholder={placeholder}
                   onChange={(e) => setValue(e.target.value)}></input>
            <PbButton onClick={() => onSubmitValue()} label={label} isDisabled={disabled || value.length === 0}></PbButton>
        </div>

    );
}

SubmitTextField.propTypes = {
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired
};

export default SubmitTextField;