import React, {useState} from 'react'
import PropTypes from "prop-types";
import PbButton from "../buttons/PbButton";
import {HOME} from "../../../shared/constants/RouteContants";

const FIRST_LEVEL_NAV = 'NAV_1';
export const SECOND_LEVEL_NAV = 'NAV_2';

const PbTabContainer = ({tabs, navLevel = FIRST_LEVEL_NAV, showNavButtons = false}) => {
    const [selectedTab, setSelectedTab] = useState(0);

    const tabBar = tabs.map((tab, index) =>
        <div className={`nav-bar-row-item ${selectedTab === index ? "selected" : ""}`}
             key={index} onClick={() => setSelectedTab(index)}>
            <i className={tab.tabIcon}></i>
            {tab.tabName}
        </div>);

    const navButtons = showNavButtons && tabs.length > 1 &&
        <div className="pb-btn-row-bordered">
            <PbButton label="Previous"
                      isDisabled={selectedTab === 0}
                      onClick={() => {setSelectedTab(selectedTab - 1); window.scrollTo(0, 0)}}
            ></PbButton>
            <PbButton label="Next"
                      isDisabled={selectedTab === tabs.length - 1}
                      onClick={() => {setSelectedTab(selectedTab + 1); window.scrollTo(0, 0)}}
            ></PbButton>
        </div>;

    if (navLevel === FIRST_LEVEL_NAV) {
        return (
            <div className="pb-tab-container">
                <div className="nav-bar-row">{tabBar}</div>
                <div>{tabs[selectedTab].tabContent}</div>
                {navButtons}
            </div>
        );
    } else {
        return (
            <div>
                <div className="nav-bar-row nav-2">{tabBar}</div>
                <div>{tabs[selectedTab].tabContent}</div>
                {navButtons}
            </div>
        );
    }


}

PbTabContainer.propTypes = {
    tabs: PropTypes.array.isRequired
};

export default PbTabContainer;