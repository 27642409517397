import React from 'react'
import {isAuthenticated} from "../../shared/services/AuthService";
import {DashboardRegisterBanner} from "./DashboardRegisterBanner";
import {DashboardContent} from "./DashboardContent";
import {DashboardActiveGames} from "./DashboardActiveGames";

export const Dashboard = () => {

    const isLoggedIn = isAuthenticated();

    return (
        <div className="dashboard">
            {isLoggedIn &&
                <DashboardActiveGames></DashboardActiveGames>
            }

            {!isLoggedIn &&
                <DashboardRegisterBanner></DashboardRegisterBanner>
            }

            <DashboardContent></DashboardContent>
        </div>
    )
}