import React, {useState} from 'react'
import logo from '../../public/logo-v2.png';
import {LOGIN} from "../../shared/constants/RouteContants";
import {post} from "../../shared/services/ApiService";
import {useNavigate} from "react-router-dom";

export const PasswordResetUpdate = () => {
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const params = (new URL(window.location)).searchParams;
    const email = params.get('email');
    const resetToken = params.get('token');

    const resetPassword = (email, resetToken) => {
        if (!resetToken || !email) {
            setError("Invalid password reset link");
            return;
        }

        if (!password || password.length < 6) {
            setError("Invalid password");
            return;
        }

        const request = {
            emailAddress: email,
            passwordResetToken: resetToken,
            password: password
        };

        post('api/password/reset/update', request).then(
            () => {
                navigate(LOGIN);
            },
            error => {
                console.error(error);
                setError(error)
            })
    }

    return (
        <div className="account-container">
            <div className="account-logo-container">
                <img className="account-logo" src={logo} alt=""></img>
            </div>

            {error &&
                <div className="account-error-container">
                    <div className="account-error">
                        {error}
                    </div>
                </div>
            }
            <div className="instruction-container">
                <div className="instruction-text">
                    Enter a new password for {email}
                </div>
            </div>
            <div className="field-container">
                <div className="account-field">
                    <input type="password"
                           value={password}
                           onChange={(e) => setPassword(e.target.value)}
                           placeholder="Password"></input>
                </div>
            </div>
            <div className="button-container">
                <button className="account-btn" onClick={() => resetPassword(email, resetToken)}>Reset Password</button>
            </div>
        </div>
    );
}