import PropTypes from "prop-types";
import React from "react";
import {buildModal, useModal} from "../modals/Modal";

export const PropHint = ({prop}) => {
    const {setModal} = useModal();

    const onClick = (prop) => {
        const modalBody = (
            <div>
                <div className="prop-hint-prop">
                    {prop.description}
                </div>
                <div className="prop-hint-explanation">
                    {prop.hint}
                </div>
            </div>
        )

        const modal = buildModal("Prop Help", modalBody)
        setModal(modal);
    }


    return (
        <a onClick={() => onClick(prop)} className="prop-hint">
            <i className="far fa-question-circle"></i>
        </a>
    );
}

PropHint.propTypes = {
    prop: PropTypes.object.isRequired
};