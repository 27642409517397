import {get, post, put} from "./ApiService";

export const getPropMetadata = (sport, category) => {
    return get(`api/tickets/games/props/metadata?sport=${sport}&category=${category}`)
}

export const saveProp = (prop) => {
    return post('api/tickets/games/props', prop);
}

export const editProp = (propId, prop) => {
    return put(`api/tickets/games/props/${propId}`, prop);
}