import React from 'react'
import PropTypes from "prop-types";

const PbLabel = ({value}) => {
    return (
        <div className={"pb-label"}>{value}</div>
    )
}

PbLabel.propTypes = {
    value: PropTypes.string.isRequired
};

export default PbLabel;