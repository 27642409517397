import React from 'react'

export const AccountSettings = () => {
  return (
      <div className="props-page-body-dark">
        <label className="games-header">Account Settings</label>
        <div>Account settings are currently not available. Send an email to ryan@propsbuddy.com for help with your
          account.
        </div>
      </div>
  )
}