import React from 'react'
import PropTypes from "prop-types";

const LabeledTextArea = ({name, label, placeholder, value, onChange}) => {

    const onUpdate = (newValue) => {
        if (name) {
            onChange(name, newValue);
        } else {
            onChange(newValue);
        }
    }

    return (
        <div className="pb-field-group pb-textarea">
            <label>{label}</label>
            <textarea className="pb-field"
                      value={value}
                      placeholder={placeholder}
                      onChange={(e) => onUpdate(e.target.value)}
            ></textarea>
        </div>
    );
}

LabeledTextArea.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired
};

export default LabeledTextArea;