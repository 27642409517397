import React from 'react'
import PropTypes from "prop-types";

import DateService from '../../../../shared/services/DateService';

export const GameItem = ({game, onClick, totalGameCount = 1}) => {
    const gameDate = DateService.parseDate(game.startTime, 'MMM D');
    const startTime = DateService.parseDate(game.startTime, 'h:mm A');

    const onItemClicked = () => {
        if (onClick) {
            onClick(game.id);
        }
    }

    let homeScore, awayScore;
    if (game.externalGame) {
        homeScore = game.externalGame.boxscore.home.stats.SCORE;
        awayScore = game.externalGame.boxscore.away.stats.SCORE;
    }

    const isClickable = !!onClick;

    return (
        <div className={`game-item ${isClickable ? 'clickable' : ''}`} onClick={onItemClicked}>
            {totalGameCount > 1 &&
                < div className="game-item-date">
                    {gameDate} {startTime}
                </div>
            }
            <div className="game-item-teams">
                <div className="team-label">{game.awayTeam} <span className="team-score">{awayScore}</span></div>
                <div className="vs-label">vs.</div>
                <div className="team-label">{game.homeTeam} <span className="team-score">{homeScore}</span></div>
            </div>
        </div>
    );
};

GameItem.propTypes = {
    game: PropTypes.object.isRequired,
    onClick: PropTypes.func
};