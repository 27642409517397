import React from 'react'
import PropTypes from "prop-types";
import {closeModal, useModal} from "./Modal";
import BaseModal from "./BaseModal";

const BasicModal = ({title, description, onAccept, acceptLabel, onCancel, cancelLabel}) => {
    const {setModal} = useModal();

    const accept = () => {
        if (onAccept) {
            onAccept();
        }
        closeModal(setModal);
    }

    const cancel = () => {
        if (onCancel) {
            onCancel();
        }
        closeModal(setModal);
    }

    return (
        <BaseModal title={title}>
            <div className="props-modal-description">
                {description}
            </div>
            <div className="props-modal-btns">
                {cancelLabel &&
                    <button className="pb-btn cancel" onClick={cancel}>{cancelLabel}</button>
                }
                <button className="pb-btn" onClick={accept}>{acceptLabel || "OK"}</button>
            </div>
        </BaseModal>
    )
}

BasicModal.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onAccept: PropTypes.func,
    acceptLabel: PropTypes.string,
    onCancel: PropTypes.func,
    cancelLabel: PropTypes.string,
    includeButtons: PropTypes.bool
};

export default BasicModal;