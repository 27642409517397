const HOCKEY = 'HOCKEY';
const FOOTBALL = 'FOOTBALL';
const BASKETBALL = 'BASKETBALL';
const BASEBALL = 'BASEBALL';
const CUSTOM = 'CUSTOM';

export default class Sport {

    static get HOCKEY() {
        return HOCKEY;
    }

    static get FOOTBALL() {
        return FOOTBALL;
    }

    static get BASKETBALL() {
        return BASKETBALL;
    }

    static get BASEBALL() {
        return BASEBALL;
    }

    static get CUSTOM() {
        return CUSTOM;
    }
}