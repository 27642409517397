export const LOGIN = '/login';
export const REGISTER = '/register';
export const PASSWORD_RESET_INIT = '/password-reset/init';
export const PASSWORD_RESET_UPDATE = '/password-reset/update';
export const HOME = '/home';
export const OPEN_GAMES = '/games/open';
export const ALL_GAMES = '/games';
export const START_GAME = '/games/create';
export const EDIT_RESULTS = '/games/results/edit/';
export const GAME_LOBBY = '/games/lobby/';
export const GAME_RESULTS_KIOSK = '/games/kiosk/';
export const TRANSACTIONS = '/transactions';
export const ACCOUNT_SETTINGS = '/account-settings';