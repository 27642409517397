import React, {useEffect} from "react";
import {AppHeader} from "./components/header/AppHeader";
import {AppFooter} from "./components/footer/AppFooter";
import {routes} from "./routes/Router";
import {downloadConfiguration, getServerVersion} from "./shared/services/ConfigService";
import {ModalProvider} from "./components/common/modals/Modal";
import {HashRouter} from "react-router-dom";

export const App = () => {
    useEffect(() => {
        downloadConfiguration();
    }, []);

    const serverVersion = getServerVersion();


    return (
        <div className="props-window">
            <HashRouter>
                <ModalProvider>
                    <AppHeader></AppHeader>
                    <div className="props-body">
                        <div className="container page-view">
                            {routes}
                        </div>
                        <AppFooter appVersion={serverVersion}></AppFooter>
                    </div>
                </ModalProvider>
            </HashRouter>
        </div>
    )
};