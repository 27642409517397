import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import PropRow from "./PropRow.jsx";
import PropCategory from "../../../shared/constants/PropCategory";
import {del} from "../../../shared/services/ApiService";
import {buildModal, buildPropBuilderModal, useModal} from "../../common/modals/Modal";
import PbButton from "../../common/buttons/PbButton";

const PropBuilder = ({game}) => {
    const [props, setProps] = useState(game.props || []);
    const {setModal} = useModal();

    useEffect(() => {
        game.props = props
    }, [props]);

    const addProp = (category) => {
        const modal = buildPropBuilderModal(game, category, addSavedProp)
        setModal(modal);
    }

    const addSavedProp = (newProp) => {
        setProps(current => [...current, newProp])
    }

    const updateProp = (index, updatedProp) => {
        const currentProps = [...props];
        currentProps[index] = updatedProp;
        setProps(currentProps);
    }

    const deleteProp = (index) => {
        const prop = props[index];
        if (!prop.id) {
            const newProps = [...props]
            newProps.splice(index, 1);
            setProps(newProps);
            return;
        }

        del('api/tickets/games/props', prop.id).then(() => {
                const newProps = [...props]
                newProps.splice(index, 1);
                setProps(newProps);
            },
            error => {
                const modal = buildModal("Error", "Couldn't delete prop.");
                setModal(modal);
                console.error(error);
            });
    };

    const propRows = props.sort((a, b) => a.order > b.order ? 1 : -1)
        .map((prop, index) => {
        return (
            <div className="pb-row" key={index}>
                <PropRow
                    index={index} prop={prop}
                    orderBefore={index === 0 ? null : props[index - 1].order}
                    orderAfter={index === props.length - 1 ? null : props[index + 1].order}
                    deleteProp={(i) => deleteProp(i)}
                    updateProp={(i, p) => updateProp(i, p)}
                ></PropRow>
            </div>
        );
    });

    return (
        <div>
            <div className="game-props">
                {propRows}
            </div>
            <div className="pb-btn-row-bordered">
                Add Prop
                <PbButton onClick={() => addProp(PropCategory.GAME)} label="GAME"></PbButton>
                <PbButton onClick={() => addProp(PropCategory.TEAM)} label="TEAM"></PbButton>
                <PbButton onClick={() => addProp(PropCategory.PLAYER)} label="PLAYER"></PbButton>
                <PbButton onClick={() => addProp(PropCategory.CUSTOM)} label="CUSTOM"></PbButton>
            </div>
        </div>
    )
}

PropBuilder.propTypes = {
    game: PropTypes.object.isRequired
}

export default PropBuilder;