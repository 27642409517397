import React, {useState} from 'react'
import logo from '../../public/logo-v2.png';
import {LOGIN} from "../../shared/constants/RouteContants";
import {post} from "../../shared/services/ApiService";
import {useNavigate} from "react-router-dom";

export const PasswordResetInit = () => {
    const [email, setEmail] = useState("");
    const [isRequestSent, setRequestSent] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const resetPassword = () => {
        if (!email) {
            setError("Must enter an email");
            return;
        }

        const request = {
            emailAddress: email
        };
        post('api/password/reset/init', request).then(
            () => {
                setRequestSent(true);
            },
            error => {
                console.error(error);
                setError("Error while resetting password");
            }
        );
    };

    return (
        <div className="account-container">
            <div className="account-logo-container">
                <img className="account-logo" src={logo} alt=""></img>
            </div>
            {error &&
                <div className="account-error-container">

                    <div className="account-error">
                        {error}
                    </div>
                </div>
            }
            {!isRequestSent &&
                <div>
                    <div className="instruction-container">
                        <div className="instruction-text">
                            Enter a valid email address and we will send you an email with instructions on how to reset
                            your password.
                        </div>
                    </div>
                    <div className="field-container">
                        <div className="account-field">
                            <input type="text" value={email}
                                   onChange={(e) => setEmail(e.target.value)}
                                   placeholder="Email"></input>
                        </div>
                    </div>
                    <div className="button-container">
                        <button className="account-btn" onClick={resetPassword}>Reset Password</button>
                    </div>
                </div>
            }
            {isRequestSent &&
                <div>
                    <div className="instruction-container">
                        <div className="instruction-text">
                            Check your email for further instructions. (Check your spam folder if you are having trouble
                            find the
                            email)
                        </div>
                    </div>
                    <div className="button-container">
                        <button className="account-btn" onClick={() => navigate(LOGIN)}>OK</button>
                    </div>
                </div>
            }
        </div>
    );
}