import React from 'react'
import PropTypes from "prop-types";
import {closeModal, useModal} from "./Modal";

const BaseModal = ({title, style, children}) => {
    const {setModal} = useModal();

    const close = () => {
        closeModal(setModal);
    }

    return (
        <div className={`props-modal-body ${style || ''}`}>
            <div className="props-modal-title">
                {title}
                <div className="props-modal-esc" onClick={close}>X</div>
            </div>
            <div className="props-modal-content">
                {children}
            </div>
        </div>
    )
}

BaseModal.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.any,
    style: PropTypes.string
};

export default BaseModal;