import React from 'react';
import PropTypes from "prop-types";

const PbButton = ({label, btnClass, iconClass, onClick, isLoading, isDisabled}) => {
    const click = () => {
        if (isLoading || isDisabled) {
            return;
        }
        onClick();
    }

    const className = `pb-btn ${btnClass ? btnClass : ''} ${isLoading || isDisabled ? 'disabled' : ''}`;
    return (
        <button className={className} onClick={click} disabled={isLoading || isDisabled}>
            {isLoading && <div className="page-loader"></div>}
            {label}
            {iconClass && <i className={"pb-btn-icon " + iconClass}></i>}
        </button>
    );
}

PbButton.propTypes = {
    label: PropTypes.string.isRequired,
    btnClass: PropTypes.string,
    iconClass: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool
};

export default PbButton;