import React, {useEffect, useState} from 'react'

import {get} from "../../shared/services/ApiService";
import DateService from "../../shared/services/DateService";
import {GAME_LOBBY} from "../../shared/constants/RouteContants";
import {PageLoader} from "../common/widgets/PageLoader";
import {useNavigate} from "react-router-dom";
import PbContainer from "../common/containers/PbContainer";

const TransactionHistory = () => {
    const [transactions, setTransactions] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const loadData = () => {
        get('api/transactions').then(response => {
            setTransactions(response);
            setLoading(false);
        })
    }

    useEffect(() => {
        loadData();
    }, []);

    if (loading) {
        return (
            <div className="props-page-body-dark">
                <PageLoader></PageLoader>
            </div>
        );
    }

    const totalWagers = transactions.reduce((total, transaction) => total + transaction.entryFee, 0.00);
    const totalWinnings = transactions.reduce((total, transaction)=> total + (transaction.winnings || 0), 0.00);
    const totalWins = transactions.reduce((total, transaction)=> total + (transaction.winnings ? 1 : 0), 0);

    const transactionRows = transactions.map((transaction, index) => {
            return (
                <div key={index} className={`transaction-row ${transaction.position ? 'winner' : ''}`}>
                    <div>
                        <div>
                            <b>{transaction.ticketName}</b>
                            <a onClick={() => navigate(GAME_LOBBY + transaction.ticketId)}>  View Game</a>
                        </div>
                        <div>
                            {DateService.parseDate(transaction.ticketDate)}
                        </div>
                        <div>
                            <b>Buy-In</b>: ${transaction.entryFee}
                        </div>
                    </div>
                    {transaction.position &&
                        <div className="transaction-row-winner">
                            <div>
                                <b>WINNER!</b>
                            </div>
                            <div>
                                <b>Place</b>: {transaction.position}
                            </div>
                            <div>
                                <b>Winnings</b>: ${transaction.winnings}
                            </div>
                        </div>
                    }
                </div>
            )
        }
    )

    return (
        <div className="props-page-body-dark">
            <label className="games-header">My Transaction History</label>
            <PbContainer label={`Totals`}>
                <div className="transaction-totals">
                    <div><b>Games Played / Wins:</b> {transactions.length} / {totalWins}</div>
                    <div><b>Wagers:</b> ${totalWagers}</div>
                    <div><b>Winnings:</b> ${totalWinnings}</div>
                </div>
            </PbContainer>

            <PbContainer label={`All Games`}>
                <div className="transactions">
                    {transactionRows}
                </div>
            </PbContainer>
        </div>
    )
}

export default TransactionHistory;