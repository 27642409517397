import React from 'react'
import PropTypes from "prop-types";

export const PropValuePill = ({label, value, isLive}) => {
    return (
        <span className="prop-value">
            <span className={`prop-value-icon ${isLive ? 'live-prop' : ''}`}>
                {label}&nbsp;{isLive && <i className="fas fa-bolt"></i>}
            </span>&nbsp;{value}
        </span>
    );
}

PropValuePill.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    pulse: PropTypes.bool
};

export default PropValuePill;
