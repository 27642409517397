import React from 'react'
import {put} from '../../../shared/services/ApiService';
import PropBuilder from "../../prop/builder/PropBuilder";
import PbButton from "../../common/buttons/PbButton";
import {useNavigate} from "react-router-dom";
import {buildModal, useModal} from "../../common/modals/Modal";
import {EDIT_RESULTS, GAME_RESULTS_KIOSK, HOME} from "../../../shared/constants/RouteContants";
import MessageDrafter, {MESSAGE_TYPE_ALL_PARTICIPANTS} from "../lobby/MessageDrafter";
import PbTabContainer, {SECOND_LEVEL_NAV} from "../../common/containers/PbTabContainer";
import PaymentManager from "./PaymentManager";
import InviteManager from "./invite/InviteManager";
import InviteMessageEditor from "./invite/InviteMessageEditor";


export const GameManager = ({ticket}) => {
    const {setModal} = useModal();
    const navigate = useNavigate();

    const updateTicketState = (newStatus) => {
        const request = {
            status: newStatus
        }
        put(`api/tickets/${ticket.id}`, request).then(result => {
                if (newStatus === 'DELETED') {
                    navigate(HOME);
                } else {
                    window.location.reload();
                }
            },
            error => {
                const modal = buildModal("Error", "Couldn't update game status.");
                setModal(modal);
                console.error(error);
            });
    }

    let tabs = [];

    if (ticket.status === 'DRAFT') {
        const gameTabs = ticket.games.map((game, index) => {
            return ({tabName: game.name, tabContent: <PropBuilder key={index} game={game}></PropBuilder>})
        });
        return (
            <div>
                <PbTabContainer tabs={gameTabs} navLevel={SECOND_LEVEL_NAV}></PbTabContainer>
                <div className="game-action-btns">
                    <PbButton label="Publish Ticket"
                              onClick={() => updateTicketState('PRE_GAME')}
                    ></PbButton>
                    <PbButton label="Delete Ticket"
                              onClick={() => updateTicketState('DELETED')}
                    ></PbButton>
                </div>
            </div>
        )
    } else if (ticket.status === 'PRE_GAME') {
        tabs = [
            {
                tabName: "Edit Details", tabIcon: "fas fa-edit",
                tabContent: <InviteMessageEditor ticket={ticket}></InviteMessageEditor>
            },
            {
                tabName: "Invites", tabIcon: "fas fa-envelope",
                tabContent: <InviteManager ticket={ticket}></InviteManager>
            },
            {
                tabName: "Payment", tabIcon: "fas fa-dollar-sign",
                tabContent: <PaymentManager ticket={ticket}></PaymentManager>
            },
            {
                tabName: "Message Users", tabIcon: "fas fa-comment",
                tabContent: <MessageDrafter ticket={ticket}
                                            messageType={MESSAGE_TYPE_ALL_PARTICIPANTS}></MessageDrafter>
            },
            {
                tabName: "More", tabIcon: "fas fa-plus",
                tabContent:
                    <div className="game-action-btns">
                        <PbButton label="Return to Draft"
                                  onClick={() => updateTicketState('DRAFT')}
                        ></PbButton>
                        <PbButton label="Lock Game"
                                  onClick={() => updateTicketState('LIVE')}
                        ></PbButton>
                        <PbButton label="Kiosk Mode"
                                  onClick={() => navigate(GAME_RESULTS_KIOSK + ticket.id)}
                        ></PbButton>
                    </div>
            }];
    } else if (ticket.status === 'LIVE') {
        tabs = [
            {
                tabName: "Payment", tabIcon: "fas fa-dollar-sign",
                tabContent: <PaymentManager ticket={ticket}></PaymentManager>
            },
            {
                tabName: "Message Users", tabIcon: "fas fa-comment",
                tabContent: <MessageDrafter ticket={ticket}
                                            messageType={MESSAGE_TYPE_ALL_PARTICIPANTS}></MessageDrafter>
            },
            {
                tabName: "More", tabIcon: "fas fa-plus",
                tabContent:
                    <div className="game-action-btns">
                        <PbButton label="Update Results"
                                  onClick={() => navigate(EDIT_RESULTS + ticket.id)}
                        ></PbButton>
                        <PbButton label="Unlock Game"
                                  onClick={() => updateTicketState('PRE_GAME')}
                        ></PbButton>
                        <PbButton label="Kiosk Mode"
                                  onClick={() => navigate(GAME_RESULTS_KIOSK + ticket.id)}
                        ></PbButton>
                    </div>
            }];
    } else if (ticket.status === 'POST_GAME' || ticket.status === 'COMPLETED') {
        tabs = [
            {
                tabName: "Payment", tabIcon: "fas fa-dollar-sign",
                tabContent: <PaymentManager ticket={ticket}></PaymentManager>
            },
            {
                tabName: "Message Users", tabIcon: "fas fa-comment",
                tabContent: <MessageDrafter ticket={ticket}
                                            messageType={MESSAGE_TYPE_ALL_PARTICIPANTS}></MessageDrafter>
            },
            {
                tabName: "More", tabIcon: "fas fa-plus",
                tabContent:
                    <div className="game-action-btns">
                        <PbButton label="Back to Live"
                                  onClick={() => updateTicketState('LIVE')}
                        ></PbButton>
                        <PbButton label="Archive Game"
                                  onClick={() => updateTicketState('ARCHIVED')}
                        ></PbButton>
                        <PbButton label="Kiosk Mode"
                                  onClick={() => navigate(GAME_RESULTS_KIOSK + ticket.id)}
                        ></PbButton>
                    </div>
            }];
    }

    return (
        <div>
            <PbTabContainer tabs={tabs} navLevel={SECOND_LEVEL_NAV}></PbTabContainer>
        </div>
    )
}