import React from "react";
import {buildModal, useModal} from "../../../common/modals/Modal";
import {translate} from "../../../../shared/services/LocalizationService";

export const TicketDetails = ({ticket}) => {
    const {setModal} = useModal();

    const onClick = (ticket) => {
        let adminFee;
        if (ticket.adminFeeFormat === 'PERCENTAGE') {
            adminFee = ticket.adminFeeValue + '%';
        } else if (ticket.adminFeeFormat === 'AMOUNT') {
            adminFee = '$' + ticket.adminFeeValue;
        } else {
            adminFee = '$0';
        }

        const modalBody = (
            <div>
                <div className="game-detail-row">
                    <b>Payout:</b> {translate(ticket.payoutFormat)}
                </div>
                <div className="ticket-detail-row">
                    <b>Buy-In:</b> ${ticket.cost}
                </div>
                <div className="ticket-detail-row">
                    <b>Admin Fee:</b> {adminFee}
                </div>
            </div>
        )

        const modal = buildModal("Ticket Details", modalBody)
        setModal(modal);
    }


    return (
        <a onClick={() => onClick(ticket)} className="ticket-details">
            Details <i className="fas fa-info-circle"></i>
        </a>
    );
}