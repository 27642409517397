import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import PbButton from "../../../common/buttons/PbButton";
import {post} from "../../../../shared/services/ApiService";
import LabeledCheckbox from "../../../common/fields/LabeledCheckbox";
import ErrorPanel from "../../../common/error/ErrorPanel";
import SubmitTextField from "../../../common/fields/SubmitTextField";
import {validateEmail} from "../../../../shared/services/EmailValidator";

export const UninvitedUsersList = ({ticket, uninvitedUsers, onInvitesUpdated}) => {
    const [isSaving, setSaving] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [allUsers, setAllUsers] = useState(uninvitedUsers);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        setAllUsers(uninvitedUsers);
    }, [uninvitedUsers])

    const sendInvites = () => {
        const invites = allUsers.filter((it, index) => selectedUsers.includes(index))
            .map(it => {
                return {
                    userId: it.userId,
                    email: it.email
                }
            });
        setSaving(true);
        post(`api/tickets/${ticket.id}/invites`, {invites}).then(() => {
                setSaving(false);
                onInvitesUpdated();
            },
            error => {
                setErrors(error);
                console.error(error);
            });
    }

    const onSelectInvite = (index, value) => {
        let currentSelectedUsers = [...selectedUsers];
        if (value) {
            currentSelectedUsers.push(index);
        } else {
            currentSelectedUsers = currentSelectedUsers.filter(i => i !== index);
        }

        setSelectedUsers(currentSelectedUsers);
    }

    const selectAll = () => {
        const allSelectedUsers = allUsers.map((u, i) => i);
        setSelectedUsers(allSelectedUsers);
    }

    const onAddUser = (email) => {
        if (!validateEmail(email)) {
            setErrors(["Invalid Email Address"]);
            return;
        }

        if (allUsers.find(u => u.email === email)) {
            setErrors(["User already exists"]);
            return;
        }

        const currentAllUsers = [...allUsers];
        currentAllUsers.push({email: email});
        setAllUsers(currentAllUsers);
        setErrors(null);
    }

    const uninvitedUserRows = allUsers.map((invite, index) => {
        const label = invite.userId ?
            `${invite.firstName} ${invite.lastName} (${invite.email})` : invite.email;

        return (
            <LabeledCheckbox key={index} name={index} label={label}
                             value={selectedUsers.includes(index)}
                             onChange={(index, value) => onSelectInvite(index, value)}
            ></LabeledCheckbox>
        )
    });

    return (
        <div className="invite-container">
            <ErrorPanel errors={errors}></ErrorPanel>
            <div className="invite-columns">
                {uninvitedUserRows}
            </div>
            <SubmitTextField onSubmit={(value) => onAddUser(value)}
                             label="Add to invite list"
                             placeholder="Email"
            ></SubmitTextField>

            <div className="props-modal-btns">
                <PbButton onClick={() => selectAll()}
                          isDisabled={isSaving} isLoading={isSaving}
                          label="Select All"></PbButton>
                <PbButton onClick={() => sendInvites()}
                          isDisabled={isSaving} isLoading={isSaving}
                          label={`Send Invites (${selectedUsers.length})`}></PbButton>
            </div>
        </div>
    )
}

UninvitedUsersList.propTypes = {
    ticket: PropTypes.object.isRequired,
    uninvitedUsers: PropTypes.array.isRequired,
    onInvitesUpdated: PropTypes.func.isRequired
};

export default UninvitedUsersList