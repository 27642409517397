import React from 'react'
import PropTypes from "prop-types";

const ErrorPanel = ({errors}) => {
    if (!errors) {
        return;
    }

    return (
        <div className="error-panel">
            {errors.map((error, index) =>
                <div className="error-panel-row" key={index}>
                    {error}
                </div>
            )}
        </div>
    )
}

ErrorPanel.propTypes = {
    errors: PropTypes.array
}

export default ErrorPanel;