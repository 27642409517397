import React, {useState} from 'react'
import PropTypes from "prop-types";
import PbContainer from "../../common/containers/PbContainer";
import DateService from "../../../shared/services/DateService";

export const ResultsTimeline = ({ticket, showAll}) => {
    const [showAllRows, setShowAllRows] = useState(showAll);

    const timeline = ticket.timeline;

    const getStyle = (event) => {
        if (event.eventType === "STAT_UPDATE") {
            return "stat-update";
        } else if (event.eventType === "PROP_RESOLVED") {
            return "prop-resolved";
        } else if (event.eventType === "GAME_START_END") {
            return "game-start-end"
        }
        return "";
    }

    let timelineView;
    if (!showAllRows && timeline.length >= 10) {
        timelineView = timeline.slice(0, 10);
    } else {
        timelineView = timeline;
    }

    const timelineEvents = timelineView.map((event, index) =>
        <div className={`event ${getStyle(event)}`} key={index}>
            <span className={`event-message ${getStyle(event)}`}>{event.message}</span>
            <span className="event-time">{DateService.parseDate(event.created, 'h:mm:ss')}</span>
        </div>
    );

    return (
        <PbContainer label="RECENT ACTIVITY" collapsible={false}>
            <div className="results-timeline">
                {timelineEvents}
            </div>
            {!showAllRows && timeline.length > 10 &&
                <a style={{margin: 5 + 'px'}} onClick={() => setShowAllRows(true)}>Show All...</a>
            }
        </PbContainer>
    );
}

ResultsTimeline.propTypes = {
    ticket: PropTypes.object.isRequired,
    showAll: PropTypes.bool
};

export default ResultsTimeline;