import React from 'react'
import {ACCOUNT_SETTINGS, HOME, LOGIN, REGISTER, TRANSACTIONS} from "../../shared/constants/RouteContants";
import logo from '../../public/logo-v2.png';
import {getFirstName, isAuthenticated, logout} from "../../shared/services/AuthService";
import {useLocation, useNavigate} from "react-router-dom";

export const AppHeader = () => {
    const location = useLocation();
    const navigate = useNavigate();

    if (location.pathname === LOGIN || location.pathname === REGISTER) {
        return;
    }

    const isLoggedIn = isAuthenticated();

    const signOut = () => {
        logout();
        navigate(HOME);
        window.location.reload();
    }

    return (
        <div className="props-header">
            <div className="header-screen">
                <div className="header-image-container">
                    <img onClick={() => navigate(HOME)} className="header-image" src={logo}></img>
                </div>
                {isLoggedIn &&
                    <div className="dropdown header-account-dropdown">
                        <button type="button" className="btn dropdown-toggle" data-toggle="dropdown">
                            <i className="fas fa-user"></i>&nbsp;&nbsp;{getFirstName()}
                        </button>
                        <div className="dropdown-menu dropdown-menu-right">
                            <a className="dropdown-item" onClick={() => navigate(ACCOUNT_SETTINGS)}><i
                                className="fas fa-user-cog"></i>&nbsp;&nbsp;Account Settings</a>
                            <a className="dropdown-item" onClick={() => navigate(TRANSACTIONS)}><i
                                className="fas fa-coins"></i>&nbsp;&nbsp;Transactions</a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" onClick={signOut}><i
                                className="fas fa-user-times"></i>&nbsp;&nbsp;Logout</a>
                        </div>
                    </div>
                }
                {!isLoggedIn &&
                    <div className="header-account-dropdown">
                        <a onClick={() => navigate(LOGIN)} className="header-logout"><i
                            className="fas fa-sign-in-alt"></i>&nbsp;&nbsp;Sign In</a>
                    </div>
                }
            </div>
        </div>
    )
}