import React from 'react'

export const AppFooter = ({appVersion}) => {
    return (
        <div className="props-footer">
            <div className="footer-item">
                Contact Us: <a href="mailto:ryan@propsbuddy.com">ryan@propsbuddy.com</a>
            </div>
            <div className="footer-right">Version: {appVersion}</div>
        </div>
    )
}
