import React, {useState} from 'react';
import PropTypes from "prop-types";
import PbContainerHeader from "./PbContainerHeader";

const PbContainer = ({label, iconClass, actionButton, collapsible, defaultCollapsed, children}) => {
    const [collapsed, setCollapsed] = useState(defaultCollapsed);

    const toggleVisibility = collapsible ? () => setCollapsed(!collapsed) : null;

    return (
        <div className="pb-container">
            <PbContainerHeader label={label}
                               iconClass={iconClass}
                               actionButton={actionButton}
                               collapsed={collapsed}
                               onToggleCollapse={toggleVisibility}
            ></PbContainerHeader>
            <div className="pb-container-body">
                {!collapsed &&
                    children
                }
            </div>
        </div>
    )
}

PbContainer.propTypes = {
    label: PropTypes.string.isRequired,
    iconClass: PropTypes.string,
    actionButton: PropTypes.node,
    collapsible: PropTypes.bool,
    defaultCollapsed: PropTypes.bool
}

export default PbContainer;