import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

const LabeledDateField = ({name, label, value, onChange}) => {
    if (!value) {
        value = new Date();
    } else if (typeof value.getMonth !== 'function') {
        value = new Date(value);
    }

    return (
        <div className="pb-field-group">
            <label>{label}</label>
            <DatePicker showTimeSelect dateFormat="Pp" onChange={(date) => onChange(name, date)} selected={value}/>
        </div>
    );
}

LabeledDateField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired
};

export default LabeledDateField;