import React, {useState} from 'react'
import logo from '../../public/logo-v2.png';
import {postLogin} from "../../shared/services/ApiService";
import {saveToken} from "../../shared/services/AuthService";
import {HOME, LOGIN, PASSWORD_RESET_INIT, REGISTER} from "../../shared/constants/RouteContants";
import {useLocation, useNavigate} from "react-router-dom";
import {useModal} from "../common/modals/Modal";
import {Registration} from "./Registration";

export const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const {setModal} = useModal();

    const onEmailChanged = (event) => {
        setEmail(event.target.value);
    }

    const onPasswordChanged = (event) => {
        setPassword(event.target.value);
    }

    const onEnter = (event) => {
        if (event.keyCode === 13) {
            login();
        }
    }

    const register = () => {
        if (location.pathname === LOGIN) {
            navigate(REGISTER);
        } else {
            setModal(
                <div style={{position: 'relative'}}>
                    <Registration></Registration>
                </div>
            );
        }
    }

    const login = () => {
        const request = {
            email: email.trim(),
            password: password
        };
        postLogin(request).then(token => {
                saveToken(token);
                if (location.pathname === LOGIN) {
                    navigate(HOME);
                } else {
                    window.location.reload();
                }
            },
            error => {
                console.error(error);
                setError("Login Failed");
            }
        );
    }

    return (
        <div className="account-container">
            <div className="account-logo-container">
                <img className="account-logo" src={logo} alt="logo"></img>
            </div>
            <div className="account-error-container">
                {error &&
                    <div className="account-error">
                        {error}
                    </div>
                }
            </div>
            <div className="field-container">
                <div className="account-field">
                    <input type="text"
                           onChange={onEmailChanged}
                           value={email}
                           placeholder="Email"
                    ></input>
                </div>
                <div className="account-field">
                    <input onKeyUp={onEnter}
                           type="password"
                           onChange={onPasswordChanged}
                           placeholder="Password"
                    ></input>
                </div>
            </div>
            <div className="button-container">
                <button className="account-btn" onClick={login}>Login</button>
                <div className="register-text">Don't have an account?
                    <a onClick={() => register()}><b> Register</b></a>
                </div>
                <div className="register-text">Forgot your password?
                    <a onClick={() => {navigate(PASSWORD_RESET_INIT); setModal()}}><b> Reset</b></a>
                </div>
            </div>
        </div>
    )
};