import React, {useState} from 'react'
import PropTypes from "prop-types";
import PbButton from "../../common/buttons/PbButton";
import {useModal, closeModal, buildModal} from "../../common/modals/Modal";
import {post} from "../../../shared/services/ApiService";

const propTypes = ["", "OVER_UNDER", "YES_NO", "ODD_EVEN", "TEAM", "TEXT"];

const CustomPropBuilder = ({game, onPropSaved}) => {
    const {setModal} = useModal();
    const [isSaving, setSaving] = useState(false);
    const [prop, setProp] = useState({
        category: "CUSTOM"
    });

    const onTypeUpdated = (type) => {
        setProp(prevProp => {
            return {
                ...prevProp,
                type: type
            }
        });
    }

    const onDescriptionUpdated = (description) => {
        setProp(prevProp => {
            return {
                ...prevProp,
                description: description
            }
        });
    }

    const onValueUpdated = (value) => {
        setProp(prevProp => {
            return {
                ...prevProp,
                value: value
            }
        });
    }

    const cancel = () => {
        closeModal(setModal);
    }

    const saveProp = () => {
        setSaving(true);
        prop.gameId = game.id;
        post('api/games/prop', prop).then(result => {
                onPropSaved(result);
                closeModal(setModal);
            },
            error => {
                const modal = buildModal("Error", "Couldn't add prop.");
                setModal(modal);
                console.error(error);
            }
        );
    }

    const isSaveDisabled = !prop.type || !prop.description
        || (prop.type === "OVER_UNDER" && !prop.value);

    return (
        <div className="props-modal-body prop-builder">
            <div className="props-modal-title">
                Custom Prop Builder
            </div>
            <div className="field-row">
                <label>Type</label>
                <select className="row-right prop-dropdown" value={prop.type || ""}
                        onChange={(e) => onTypeUpdated(e.target.value)}>
                    {propTypes.map((option, index) => {
                        return <option key={index}>{option}</option>;
                    })}
                </select>
            </div>
            <div className="field-row">
                <label>Custom Prop</label>
                <div className="row-right">
                    <textarea className="row-right" rows="2" wrap="soft" value={prop.description || ""}
                              onChange={(e) => onDescriptionUpdated(e.target.value)}></textarea>
                </div>
            </div>
            {prop.type === "OVER_UNDER" &&
                <div className="field-row">
                    <label>O/U Value</label>
                    <input className="row-right" type="number" value={prop.value || ""}
                           onChange={(e) => onValueUpdated(e.target.value)}></input>
                </div>
            }

            <div className="props-modal-btns">
                <PbButton onClick={cancel} label="Cancel" isLoading={isSaving} btnClass="cancel"></PbButton>
                <PbButton onClick={saveProp} label="Save" isLoading={isSaving} isDisabled={isSaveDisabled}></PbButton>
            </div>
        </div>
    )
}

CustomPropBuilder.propTypes = {
    game: PropTypes.object.isRequired,
    onPropSaved: PropTypes.func.isRequired
}

export default CustomPropBuilder;