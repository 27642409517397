import axios from 'axios';

const API_PREFIX = '/propsbuddy/';

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response && error.response.status === 400) {
        return Promise.reject(error.response.data);
    }
    if (error.response && error.response.status === 401) {
        return Promise.reject("Your session has expired. Try logging back in.");
    }
    return Promise.reject("An unexpected error occurred. If the problem persists ask ryan@propsbuddy.com for help.");
});

axios.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.authorization = token;
    }
    return config;
});

export async function get(apiRoute, parameter) {
    if (parameter) {
        apiRoute += '/' + parameter;
    }
    return axios.get(API_PREFIX + apiRoute).then(response => {
        return response.data;
    });
}

export const post = (url, requestData) => {
    return axios.post(API_PREFIX + url, requestData).then(response => {
        return response.data;
    });
}

export const put = (url, requestData) => {
    return axios.put(API_PREFIX + url, requestData).then(response => {
        return response.data;
    });
}

export async function del(apiRoute, parameter) {
    if (parameter) {
        apiRoute += '/' + parameter;
    }
    return axios.delete(API_PREFIX + apiRoute).then(response => {
        return response.data;
    });
}

export const postLogin = (requestData) => {
    return axios.post(API_PREFIX + 'api/login', requestData).then(response => {
        return response.headers.authorization;
    });
}