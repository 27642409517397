import React from 'react'
import PropTypes from "prop-types";

import DateService from '../../../../shared/services/DateService';

import PbContainer from "../../../common/containers/PbContainer";
import {GameItem} from "./GameItem";
import {TicketDetails} from "./TicketDetails";

const Ticket = ({ticket, onClick, children}) => {
    const gameDate = DateService.parseDate(ticket.startTime, 'MMM D/YY');
    const startTime = DateService.parseDate(ticket.startTime, 'h:mm A');

    const onItemClicked = () => {
        if (onClick) {
            onClick(ticket.id);
        }
    }

    const gameItems = ticket.games.map((game, index) => {
        return (
            <GameItem game={game} totalGameCount={ticket.games.length}></GameItem>
        )
    })


    return (
        <PbContainer label={ticket.name} actionButton={<TicketDetails ticket={ticket}></TicketDetails>}>
            <div className={`ticket ${!!onClick ? 'clickable' : ''}`} onClick={onItemClicked}>
                <div className="ticket-date">
                    <div>
                        {gameDate}
                    </div>
                    <div>
                        {startTime}
                    </div>
                </div>
                {gameItems}
            </div>
            {children}
        </PbContainer>
    );

};

Ticket.propTypes = {
    ticket: PropTypes.object.isRequired,
    onClick: PropTypes.func
};

export default Ticket;