import React from 'react'

import PropRow from "./PropRow";
import PropType from "../../../shared/constants/PropType";
import PbContainer from "../../common/containers/PbContainer";

export const PropsEditor = ({game, viewModel, onPropUpdated}) => {

    const getCurrentValue = (prop) => {
        if (viewModel) {
            return viewModel[prop.key];
        }
        return prop.answer;
    }

    const getPropsByType = (type) => {
        return game.props
            .filter(prop => prop.type === type)
            .map((prop) => {
                return (
                    <div key={prop.id} className="pb-row">
                        <PropRow game={game} propData={prop}
                                 currentValue={getCurrentValue(prop)}
                                 onPropUpdated={onPropUpdated}
                        ></PropRow>
                    </div>
                )
            });
    }

    const propsOverUnder = getPropsByType(PropType.OVER_UNDER);
    const propsOddEven = getPropsByType(PropType.ODD_EVEN);
    const propsYesNo = getPropsByType(PropType.YES_NO);
    const propsTeam = getPropsByType(PropType.TEAM);
    const propsColour = getPropsByType(PropType.COLOUR);
    const propsText = getPropsByType(PropType.TEXT);

    return (
        <div>
            {propsOverUnder.length > 0 &&
                <PbContainer label="Over/Under" collapsible={true}>
                    {propsOverUnder}
                </PbContainer>
            }
            {propsOddEven.length > 0 &&
                <PbContainer label="Odd/Even" collapsible={true}>
                    {propsOddEven}
                </PbContainer>
            }
            {propsYesNo.length > 0 &&
                <PbContainer label="Yes/No" collapsible={true}>
                    {propsYesNo}
                </PbContainer>
            }
            {propsTeam.length > 0 &&
                <PbContainer label="Team" collapsible={true}>
                    {propsTeam}
                </PbContainer>
            }
            {propsColour.length > 0 &&
                <PbContainer label="Colour" collapsible={true}>
                    {propsColour}
                </PbContainer>
            }
            {propsText.length > 0 &&
                <PbContainer label="Text" collapsible={true}>
                    {propsText}
                </PbContainer>
            }
        </div>
    )
}
