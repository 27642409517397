import React, {useEffect, useState} from 'react';
import PbButton from '../../common/buttons/PbButton.jsx';
import {buildModal, useModal} from "../../common/modals/Modal";
import {useNavigate} from "react-router-dom";
import {GAME_LOBBY, HOME} from "../../../shared/constants/RouteContants";
import LabeledField from "../../common/fields/LabeledField";
import LabeledDateField from "../../common/fields/LabeledDateField";
import LabeledDropdown from "../../common/fields/LabeledDropdown";
import LabeledCheckbox from "../../common/fields/LabeledCheckbox";
import PbContainer from "../../common/containers/PbContainer";
import {post} from "../../../shared/services/ApiService";
import {GameItem} from "../common/game-item/GameItem";
import GameSelectorModal from "./GameSelectorModal";
import PbLabel from "../../common/labels/PbLabel";

const payoutFormats = ["WINNER_TAKE_ALL", "TOP_2", "TOP_3"];
const adminFeeFormats = ["NONE", "PERCENTAGE", "FIXED"];
const ticketFormats = ["PROPS", "SPREAD"];

const defaultTicket = () => {
    const defaultTicket = {};
    defaultTicket.cost = 5;
    defaultTicket.addDefaultProps = true;
    defaultTicket.payoutFormat = "WINNER_TAKE_ALL";
    defaultTicket.adminFeeValue = 0;
    defaultTicket.adminFeeFormat = "NONE";
    defaultTicket.format = "PROPS";
    defaultTicket.games = [];

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1)
    tomorrow.setHours(19);
    tomorrow.setMinutes(0);
    tomorrow.setMilliseconds(0);
    defaultTicket.startTime = tomorrow;

    return defaultTicket;
}

const TicketBuilder = () => {
    const [ticket, setTicket] = useState(defaultTicket());

    const {setModal} = useModal();
    const navigate = useNavigate();

    const onChange = (fieldName, value) => {
        setTicket(prevTicket => {
                return {
                    ...prevTicket,
                    [fieldName]: value
                }
            }
        );
    }

    const onSave = () => {
        post('api/tickets', ticket).then(result => {
                const modal = buildModal("Game Created", "Add props to your game.");
                setModal(modal);
                navigate(GAME_LOBBY + result.id);
            },
            error => {
                const modal = buildModal("Error", "Failed to create game.");
                setModal(modal);
                console.error(error);
            });
    }

    const addGame = (newGame) => {
        const games = [...ticket.games];
        games.push(newGame);
        setTicket(prevTicket => {
                return {
                    ...prevTicket,
                    games: games
                }
            }
        );
    }

    const openGameModal = () => {
        setModal(<GameSelectorModal onGameSelected={addGame}></GameSelectorModal>);
    }

    const games = ticket.games;

    const gameItems = games.map((game, index) => {
        return <GameItem game={game} totalGameCount={ticket.games.length}></GameItem>;
    });


    return (
        <div className="props-page-body-dark">
            <label className="games-header">Game Builder</label>
            <PbContainer label="Game Details">
                <div className="game-details">
                    <div className="pb-field-row">
                        <LabeledField name="name" label="Ticket Name" type="text" value={ticket.name}
                                      onChange={onChange}></LabeledField>
                        <LabeledDateField name="startTime" label="Lock Time" value={ticket.startTime}
                                          onChange={onChange}></LabeledDateField>
                    </div>
                    <div className="pb-field-row">
                        <LabeledField name="cost" label="Entry Cost" type="number" value={ticket.cost}
                                      onChange={onChange}></LabeledField>
                        <LabeledDropdown name="payoutFormat" label="Payout Format" options={payoutFormats}
                                         value={ticket.payoutFormat} onChange={onChange}></LabeledDropdown>
                    </div>
                    <div className="pb-field-row">
                        <LabeledField name="adminFeeValue" label="Admin Fee" type="number" value={ticket.adminFeeValue}
                                      onChange={onChange}></LabeledField>
                        <LabeledDropdown name="adminFeeFormat" label="Admin Fee Format" options={adminFeeFormats}
                                         value={ticket.adminFeeFormat} onChange={onChange}></LabeledDropdown>
                    </div>
                    <div className="pb-field-row">
                        <LabeledDropdown name="format" label="Ticket Format" options={ticketFormats}
                                         value={ticket.format} onChange={onChange}></LabeledDropdown>
                        {ticket.format === 'PROPS' &&
                            <LabeledCheckbox name="addDefaultProps" label="Include Default Props"
                                             value={ticket.addDefaultProps} onChange={onChange}></LabeledCheckbox>
                        }
                    </div>
                    <div className="ticket-builder-games">
                        <div className="ticket-builder-games-header">
                            <div>Games</div>
                            <i onClick={openGameModal} className="fas fa-plus-square"></i>
                        </div>
                        <div className="ticket-builder-games-body">
                            {games.length === 0 && <PbLabel value={"Add games to continue..."}></PbLabel>}
                            {games.length > 0 && gameItems}
                        </div>
                    </div>
                    <div className="pb-btn-row-bordered">
                        <PbButton label="Abandon" onClick={() => navigate(HOME)}></PbButton>
                        <PbButton label="Continue" isDisabled={games.length === 0} onClick={onSave}></PbButton>
                    </div>
                </div>
            </PbContainer>
        </div>

    )
}

export default TicketBuilder;