import React, {useEffect, useState} from 'react'

import {GAME_LOBBY} from "../../../shared/constants/RouteContants";
import {get} from "../../../shared/services/ApiService";
import {PageLoader} from "../../common/widgets/PageLoader";
import {useNavigate} from "react-router-dom";
import Ticket from "../common/game-item/Ticket";

export const OpenGames = () => {
    const [tickets, setTickets] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const loadData = () => {
        get('api/tickets?statuses=PRE_GAME').then(response => {
            setTickets(response);
            setLoading(false);
        })
    }

    useEffect(() => {
        loadData();
    }, []);

    if (loading) {
        return (
            <div className="props-page-body-dark">
                <PageLoader></PageLoader>
            </div>
        );
    }

    const goToTicket = (ticketId) => {
        navigate(GAME_LOBBY + ticketId);
    }

    const openTickets = tickets.map((ticket, index) =>
        <Ticket key={index} onClick={goToTicket} ticket={ticket}></Ticket>
    );

    return (
        <div className="props-page-body-dark">
            <div className="games-header">Open Games</div>
            {openTickets.length > 0 && openTickets}
            {openTickets.length === 0 &&
                <div>There are currently no games to join. Want to start your own game? Contact <a>ryan@propsbuddy.com</a> to get started.</div>}
        </div>
    )
}