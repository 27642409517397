import React, {useState} from "react";
import {post} from "../../../shared/services/ApiService";
import BasicModal from "../../common/modals/BasicModal";
import {useModal} from "../../common/modals/Modal";
import LabeledTextArea from "../../common/fields/LabeledTextArea";
import PbButton from "../../common/buttons/PbButton";

export const MESSAGE_TYPE_ADMIN = 'ADMIN_QUESTION'
export const MESSAGE_TYPE_ALL_PARTICIPANTS = 'ALL_PARTICIPANTS';
export const MESSAGE_TYPE_ONE_PARTICIPANT = 'ONE_PARTICIPANT';

const MessageDrafter = ({ticket, messageType, toUser}) => {
    const [isSaving, setSaving] = useState(false);
    const [message, setMessage] = useState();
    const {setModal} = useModal();

    const onChange = (messageVal) => {
        setMessage(messageVal);
    }
    const sendMessage = () => {
        const request = {
            messageType: messageType,
            message: message,
            toUserId: toUser ? toUser.id : null
        };
        setSaving(true);
        post(`api/tickets/${ticket.id}/message`, request).then(() => {
                setSaving(false);
                setModal(
                    <BasicModal title="Message Sent"
                                description="Message sent successfully"
                    ></BasicModal>
                );
            },
            error => {
                setModal(
                    <BasicModal title="Message Failed"
                                description="There was an error sending the message. Try again later or email ryan@propsbuddy.com"
                    ></BasicModal>
                );
                console.error(error);
            });
    }

    const getDescription = () => {
        if (messageType === MESSAGE_TYPE_ADMIN) {
            return `Have a question about the game or need a clarification? Send a message to your game admin ${ticket.adminName}`;
        } else if (messageType === MESSAGE_TYPE_ONE_PARTICIPANT) {
            return `Enter a message to ${toUser.firstName}`;
        } else {
            return "Enter your message to all participants";
        }
    }

    return (
        <div>
            <div className="message-drafter-description">
                {getDescription()}
            </div>
            <LabeledTextArea onChange={onChange}
                             placeholder="Enter your message"></LabeledTextArea>
            <div className="pb-btn-row">
                <PbButton onClick={sendMessage} label="Send"></PbButton>
            </div>
        </div>
    )
}

export default MessageDrafter;