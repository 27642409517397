import React, {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import PropRadio from "../../common/fields/PropRadio";
import PropCategory from "../../../shared/constants/PropCategory";
import PbButton from "../../common/buttons/PbButton";
import {useModal, closeModal, buildModal} from "../../common/modals/Modal";
import * as PropService from "../../../shared/services/PropBuilderService";
import * as PropBuilderService from "../../../shared/services/PropBuilderService";

const PlayerPropBuilder = ({game, onPropSaved}) => {
    const {setModal} = useModal();
    const teams = [game.externalGame.homeTeam.nickName, game.externalGame.awayTeam.nickName];

    const [loading, setLoading] = useState(true);
    const [isSaving, setSaving] = useState(false);
    const [team, setTeam] = useState("");
    const [players, setPlayers] = useState([]);
    const [propMetadata, setPropMetadata] = useState(null);
    const [prop, setProp] = useState({
        type: "OVER_UNDER",
        category: "PLAYER"
    });

    const fetchMetadata = () => {
        PropService.getPropMetadata(game.sport, PropCategory.PLAYER).then(response => {
            setPropMetadata(response);
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchMetadata();
    }, []);

    if (loading) {
        return (
            <div className="props-modal-body prop-builder">
                <div className="page-loader"></div>
            </div>
        );
    }

    const onTeamToggled = (value) => {
        setTeam(value);

        const activeTeam = value === teams[0] ? game.externalGame.homeTeam : game.externalGame.awayTeam;
        setPlayers(activeTeam.roster);
        onPlayerUpdated(null);
    }

    const onPlayerUpdated = (playerId) => {
        const player = players.find(p => p.id === playerId);

        setProp(prevProp => {
            return {
                ...prevProp,
                playerName: player ? player.fullName : null,
                externalPlayerId: player ? player.id : null
            }
        });
    }

    const onStatUpdated = (stat) => {
        setProp(prevProp => {
            return {
                ...prevProp,
                stat: stat
            }
        });
    }

    const onValueUpdated = (value) => {
        setProp(prevProp => {
            return {
                ...prevProp,
                value: value
            }
        });
    }

    const getMetadataForStat = () => {
        if (!prop || !prop.stat) {
            return;
        }

        return propMetadata.find(metadata => metadata.stat === prop.stat)
    }

    const isPosition = (player) => {
        const metadata = getMetadataForStat();
        if (!metadata || !metadata.playerPositions) {
            return true;
        }

        return metadata.playerPositions.split(',').includes(player.position);
    }
    const getPlayers = () => {
        return players
            .filter((player) => isPosition(player))
            .sort((a, b) => {
            if (a.fullName < b.fullName) {
                return -1;
            }
            return 1;
        })
            .map((option, index) => {
                return <option key={index} value={option.id}>{option.fullName}, {option.position}</option>;
            });
    }

    const statOptions = propMetadata.map((option, index) => {
        return <option key={index} value={option.stat}>{option.description}</option>;
    });

    const cancel = () => {
        closeModal(setModal);
    }

    const saveProp = () => {
        setSaving(true);
        prop.gameId = game.id;
        PropBuilderService.saveProp(prop).then(result => {
                onPropSaved(result);
                closeModal(setModal);
            },
            error => {
                const modal = buildModal("Error", "Couldn't add prop.");
                setModal(modal);
                console.error(error);
            }
        );
    }

    const isSaveDisabled = !prop.stat || !prop.playerName || !prop.value;

    return (
        <div className="props-modal-body prop-builder">
            <div className="props-modal-title">
                Player Prop Builder
            </div>
            <div className="field-row">
                <label>Stat</label>
                <select className="row-right prop-dropdown" value={prop.stat || ""}
                        onChange={(e) => onStatUpdated(e.target.value)}>
                    <option value="" disabled hidden></option>
                    {statOptions}
                </select>
            </div>
            <div className="field-row">
                <label>Team</label>
                <div className="row-right">
                    <PropRadio updateValue={onTeamToggled} currentValue={team} options={teams}></PropRadio>
                </div>
            </div>
            <div className="field-row">
                <label>Player</label>
                <select className="row-right prop-dropdown" value={prop.externalPlayerId || ""}
                        onChange={(e) => onPlayerUpdated(e.target.value)}>
                    <option value="" disabled hidden></option>
                    {getPlayers()}
                </select>
            </div>

            <div className="field-row">
                <label>O/U Value</label>
                <input className="row-right" type="number" value={prop.value || ""}
                       onChange={(e) => onValueUpdated(e.target.value)}></input>
            </div>

            <div className="props-modal-btns">
                <PbButton onClick={cancel} label="Cancel" isLoading={isSaving} btnClass="cancel"></PbButton>
                <PbButton onClick={() => saveProp()} isDisabled={isSaveDisabled} isLoading={isSaving}
                          label="Save"></PbButton>
            </div>
        </div>
    )
}

PlayerPropBuilder.propTypes = {
    game: PropTypes.object.isRequired,
    onPropSaved: PropTypes.func.isRequired
}

export default PlayerPropBuilder;